.bribeModalWrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.906);
    z-index: 2;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.bribeModalWrapper > * {
    margin-top: 30vh;

}

.bribeModal{
    max-width: 500px;
    padding: 20px;
    box-sizing: border-box;
}

.bribeTitle{
    font-size: 20px;
}

.bribeControlWrapper{
    justify-content: space-around;
    display: flex;  
    padding: 30px;
    align-items: center;
}

.ticketsToPay{
    font-size: 50px;
    padding-top: 10px;
}

.bribeButtonControl{
    width: 50px;
    font-size: 20px;
}

.bribeButtonWrapper{
    display: flex;
}

.bribeButtonWrapper > * {
    margin: 10px;
}
@media (max-width: 700px){
    .bribeModal{
        max-width: 80vw;
        padding: 20px;
        box-sizing: border-box;
    }

    .bribeTitle{
        font-size: 18px;
    }
    .ticketsToPay{
        font-size: 40px;
    }
    
    
}