.applicationContent{
  min-height: calc(100vh - 340px);
  padding: 30px;
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.walletImg{
  height: 100px;
}

@media (max-width: 800px) {
  .applicationContent{
    min-height: calc(100vh - 280px);
  }
  .applicationContent{
    max-width: 100vw;
    padding: 20px 10px;
  }
}

