.customButton{
    background-color: var( --main-light-blue);
    border: 1px solid var( --main-light-blue);
    color: var(--main-black);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
}
.customButton:disabled{
    border: 1px solid gray;
    color: gray;
    cursor: not-allowed;
    background-color: var(--main-black);
}

.rejectButton{
    background-color: var(--main-black);
    border: 1px solid var( --main-light-blue);
    color: var( --main-light-blue);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
}
.rejectButton:disabled{
    border: 1px solid gray;
    color: gray;
    cursor: not-allowed;
}
.tooltip {
    position: relative;
    display: inline-block;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: var(--bg);
    color: var(--primary-color);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 800px) {
    .customButton{
        padding: 15px;
        font-weight: 600;
    }
    
    .rejectButton{
        padding: 15px;
        font-weight: 600;
    }
}