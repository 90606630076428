.spinnerWrapper{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: var(--main-black);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}