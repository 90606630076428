.home{
    text-align: center;
}

.home > * {
    margin-bottom: 40px;
}

.homeHeader{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.homeHeader > div{
    overflow: hidden;
}
.homeHeader button{
    margin-top: 15px;
}

.statBlock {
    box-sizing: border-box;
    min-height: 200px;
    justify-content: space-between;
    padding: 20px;
    overflow: hidden;
    text-align: left;
    display: flex;
    align-items: center;
}

.statBlock > img{
    width: 30%;
    height: auto;
}

.statBlock > div{
    width: 56%;
    padding-right: 5px;
    box-sizing: border-box;
}

.statBlockTitle{
    font-size: 20px;
}

@media (max-width: 600px) {
    .homeHeader{
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }
    
    .statBlock {
        padding: 30px;
        justify-content: space-between;
    }

    .statBlock > div{
        width: 60%;
    }
}

