.footerBottom{
    text-align: center;
    max-width: 1300px;
    margin: auto;
    overflow: hidden;
}

.footerBottom:after{
    content: "\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"
}

.socialMediaWrapper{
    display: flex;
    justify-content: space-around;
    max-width: 200px;
    margin: auto;
    padding: 30px;
}

.socialMediaWrapper img{
    height: 20px;
    cursor: pointer;
}

