.navbar{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 10px;
    text-align: center;
    max-width: 1300px;
    margin: auto;
}

.navbar *{
    font-family: 'san-francisco' !important;
    letter-spacing: normal;
    font-size: 30px;
}

.navbar > div:first-of-type{
    margin: auto;
}
.logo{
    height: 120px;
    width: auto;
}

.navbarBottom{
    text-align: center;
    max-width: 1300px;
    margin: auto;
    overflow: hidden;
}
.navbarBottom:after{
    content: "\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"
}

@media (max-width: 800px){
    .logo{
        height: 80px;
    }
    .navbar *{
        font-family: 'san-francisco' !important;
        letter-spacing: normal;
        font-size: 20px;
    }
}